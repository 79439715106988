import { Box } from '@material-ui/core'

interface ECircularProgressProps {
  size?: string
  isMale?: boolean
}

export default function ECircularProgress({
  size = '80px',
  isMale = true,
}: ECircularProgressProps) {
  const fillColor = isMale ? '#9c0c19' : '#00ada9'

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <Box style={{ marginTop: `calc(${size} * -1)` }}>
        <svg
          id="epbL5FDWCqB1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 80 80"
          shapeRendering="geometricPrecision"
          textRendering="geometricPrecision"
          style={{ width: size, height: size }}
        >
          <style>
            {`
              #epbL5FDWCqB4_tr {
                animation: epbL5FDWCqB4_tr__tr 1500ms linear infinite normal forwards;
              }
              @keyframes epbL5FDWCqB4_tr__tr {
                0% {
                  transform: translate(35.896825px,44.744687px) rotate(330.175644deg);
                }
                100% {
                  transform: translate(35.896825px,44.744687px) rotate(690.182908deg);
                }
              }

              #epbL5FDWCqB5_ts {
                animation: epbL5FDWCqB5_ts__ts 1500ms linear infinite normal forwards;
              }
              @keyframes epbL5FDWCqB5_ts__ts {
                0% {
                  transform: translate(36.288461px,44.474529px) scale(0.849118,0.849118);
                }
                46.666667% {
                  transform: translate(36.288461px,44.474529px) scale(0.745468,0.745468);
                }
                100% {
                  transform: translate(36.288461px,44.474529px) scale(0.85017,0.85017);
                }
              }
            `}
          </style>
          <g transform="matrix(1.14304 0 0 1.14304 -1.479162 -10.836166)">
            <g>
              {/* spin element */}
              <g
                id="epbL5FDWCqB4_tr"
                transform="translate(35.896825 44.744687) rotate(330.175644)"
              >
                <path
                  d="M38.976229,10.61483l.129104-9.120504l8.854732,4.406406"
                  transform="skewX(-0.000011) skewY(0) scale(0.85887,0.989413) translate(-10.763389,-8.092277)"
                  fill={fillColor}
                  fillRule="evenodd"
                  clipRule="evenodd"
                />
              </g>

              {/* scale element */}
              <g
                id="epbL5FDWCqB5_ts"
                transform="translate(36.288461 44.474529) scale(0.849118 0.849118)"
              >
                <path
                  d="M9.8935,25.0421l.2844.6846.61-.4197L25.6442,15.094l.61-.4196-.536-.5118c-1.5175-1.4416-4.278-2.8289-7.4775-2.3961-1.552.209-3.0844.8261-4.5526,1.8365-5.24472,3.6057-4.85342,8.8867-3.7946,11.4391ZM22.402,34.791c1.6293-.2188,3.2603-.8656,4.7186-1.8678c3.1831-2.1887,3.8769-4.8004,4.0857-7.3232l9.0147-6.1993c.6708,4.2014.3141,8.2366-1.0473,11.7304-1.3202,3.6616-3.7634,6.7949-7.0631,9.0643-2.6109,1.7938-5.6558,2.9671-8.8043,3.3934-7.706,1.0384-15.21641-2.2842-19.59964-8.6727-6.46635-9.4231-4.253355-22.2264,4.93237-28.54248c2.72427-1.87277,5.69687-3.03626,8.83557-3.4592c7.6139-1.0269,15.2295,2.53927,19.8741,9.30788.6972,1.0138.9816,1.5897,1.2775,2.2134L14.525,31.006l-.5754.395.4768.5101c2.1176,2.2661,4.9488,3.2881,7.9756,2.8799Z"
                  transform="translate(-20.06555 -23.180558)"
                  fill={fillColor}
                  fillRule="evenodd"
                  clipRule="evenodd"
                />
              </g>
            </g>
          </g>
        </svg>
      </Box>

    </Box>
  )
}

ECircularProgress.defaultProps = {
  size: '80px',
  isMale: true,
}
